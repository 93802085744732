'use client';

import React, { useEffect, useState } from 'react';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AppProvider } from 'src/context/app';
import {
    SharedProvider,
    getAnonymousUserId,
    getUserUuid,
    ToastProvider,
} from '@get-mosh/msh-shared';
import Brands from './constants/Brands';
import DatadogInit from 'src/analytics/Datadog';
import { datadogRum } from '@datadog/browser-rum';
import AnalyticsProvider from 'src/analytics/Analytics';

type ProvidersProps = {
    children: React.ReactNode;
};

function Providers({ children }: ProvidersProps) {
    const [client] = useState(
        () =>
            new QueryClient({
                defaultOptions: {
                    queries: {
                        // Avoid refetching data immediately on page load
                        staleTime: 1000 * 60,
                    },
                },
            }),
    );

    useEffect(() => {
        const userId = getUserUuid();
        const anonymousId = getAnonymousUserId();

        datadogRum.setUser({
            id: userId,
            anonymous_id: anonymousId,
            brand: Brands.MOSH,
        });
    }, []);

    return (
        <AppProvider>
            <QueryClientProvider client={client}>
                <AnalyticsProvider>
                    <SharedProvider theme="mosh">
                        <ToastProvider>
                            <DatadogInit />
                            <ReactQueryDevtools initialIsOpen={false} />
                            {children}
                        </ToastProvider>
                    </SharedProvider>
                </AnalyticsProvider>
            </QueryClientProvider>
        </AppProvider>
    );
}

export default Providers;
