import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/next@14.2.26_@babel+core@7.26.0_@playwright+test@1.50.1_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.80.7/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/src/providers.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/styles/tailwind.css");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/styles/index.css");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/swiper@8.4.7/node_modules/swiper/swiper.min.css");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/@get-mosh+msh-shared@1.8.9_html-react-parser@5.1.18_@types+react@18.3.12_react@18.3.1__react-_h4yuiovbuik5aq7awg5ysex5y4/node_modules/@get-mosh/msh-shared/dist/style.css");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/@fortawesome+fontawesome-svg-core@6.7.2/node_modules/@fortawesome/fontawesome-svg-core/styles.css");
